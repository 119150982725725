import Vue from 'vue'
import axios from 'axios'
import { config } from '@/config.settings'
import apiMethod from '../api_call'
import { hasFeatureFlagAccess } from '@/contexts/shared/feature-flags/FeatureFlags.helpers'
import { getUserRoleNameByRoleHash } from '@/contexts/shared/users/roles/UserRoles.helpers'

const localStorage = {
  state() {
    return {
      token: null,
      loading: false,
      main_titles: {},
      loading_cpt: 0,
      sidebar: false,
      perimeter: null,
      ptag_policy: null,
      ptag_dashboard: null,
      perimeter_indicator: null,
      security_base_by_perimeters: {},
      security_base_tab_index: 0,
      security_base_preferences: {
        perimeter: null,
        score_type: null,
      },
      user: {
        id: '',
        name: '',
        initials: '',
        cgu_accept: false,
        email: '',
        lang: '',
        key: '',
        instance: '',
        instance_type: '',
        instance_role: '',
        customer_id: null,
        customer: '',
        customer_location: '',
        customer_type: '',
        context_type: null,
        customers: [],
        features: [],
        all_features: true,
        is_local_provider: false,
        has_policy_benchmark: null,
        roles: '',
        user_persona_tenacy: '',
        logo_main: null,
        mfa_activated: false,
        jira_integration: false,
        has_integration: false,
        connector_allowed: false,
        has_connector: false,
        jira_domain: '',
        mfa_enabled: false,
        api_public_enable: false,
        dashboard_cockpit: null,
        tag: null,
        perimeter: null,
        local_tag_id: null,
        local_perimeter_id: null,
        global_admin: false,
        global_auditor: false,
        send_password_reminder: false,
        remind_days: null,
        is_hour: null,
        has_saml: false,
        saml_available_for_customer: false,
        impersonate_contributor: false,
        sandbox_enable: false,
        isp_enable: false,
        is_sandbox: false,
        risk_analysis_enable: false,
        multi_tenants_enable: false,
        enable_intercom: false,
        onboarding_enabled: false,
        onboarding_laststep: '0',
        customer_options: [],
        customer_features_flags: [],
      },
      explicitly_logout: false,
      groupsusers: [],
      per_page: 20,
      per_page_options: [20],
      linechart_preference: {
        show_dots: false,
        dot_end_period: false,
        dot_placement: 'MIDDLE',
      },
      dashboard_preference: {
        show_delta: true,
        show_dots: false,
        radar_previous_values: false,
        palette: [
          '#35ABB0',
          '#0A4650',
          '#1f77b4',
          '#ff7f0e',
          '#2ca02c',
          '#d62728',
          '#9467bd',
          '#8c564b',
          '#e377c2',
          '#7f7f7f',
          '#bcbd22',
          '#17becf',
        ],
        show_sub_info: true,
        show_icon: true,
        is_pie: false,
        show_value_pie: false,
        custom_colors: false,
        show_legend: true,
        dot_end_period: false,
        dot_placement: 'MIDDLE',
        offset_grid: false,
        comment_size: 'md',
        pill_color: '#555555',
        force_pill_color: false,
        chart_y_auto: false,
      },
      is_dev_env: false,
      perimeter_tree_grouping: {},
      show_operated: false,
      show_separate: false,
      show_auto_metrics: false,
      show_activity: true,
      policy_priority_visible: false,
      policy_mapping_visible: false,
      perimeters_outside_open: false,
      policy_score: 'coverage',
      perimeter_score: 'eval',
      perimeter_risk: false,
      perimeter_run: false,
      policy_perimeter_collapse: {},
      runbooks_open_groups: [],
      task_gantt_open_group: {},
      exemptions_open_groups: [],
      projects_open_groups: [],
      catalog_public: true,
      nb_reviews: 0,
      me_show_only_have_to_complete: true,
      me_show_only_my_metric: true,
      me_show_auto: false,
      tutorial_steps: {
        risk_analysis_step: 'END',
      },
      hide_policy_suggestions: false,
      return_recap_from_header: false,
      risk_matrix_level: { id: 'current', name: 'Current risk' },
      matrix_display_risk: 'count',
      risk_matrix_scale_level_perimeter: { id: 'current', name: 'Current risk' },
      risk_tile: null,
      is_zoomed: false,
    }
  },
  getters: {
    token: (state) => state.token,
    hide_policy_suggestions: (state) => state.hide_policy_suggestions,
    main_titles: (state) => state.main_titles,
    me_show_only_have_to_complete: (state) => state.me_show_only_have_to_complete,
    me_show_only_my_metric: (state) => state.me_show_only_my_metric,
    me_show_auto: (state) => state.me_show_auto,
    catalog_public: (state) => state.catalog_public,
    mfa_activated: (state) => state.user.mfa_activated || false,
    context_type: (state) => state.user.context_type || null,
    jira_integration: (state) => state.user.jira_integration || false,
    has_integration: (state) => state.user.has_integration || false,
    connector_allowed: (state) => state.user.connector_allowed || false,
    has_connector: (state) => state.user.has_connector || false,
    jira_domain: (state) => state.user.jira_domain || '',
    send_password_reminder: (state) => state.user.send_password_reminder || false,
    is_hour: (state) => state.user.is_hour || false,
    has_saml: (state) => state.user.has_saml || false,
    saml_available_for_customer: (state) => state.user.saml_available_for_customer || false,
    remind_days: (state) => state.user.remind_days || '',
    mfa_enabled: (state) => state.user.mfa_enabled || false,
    policy_perimeter_collapse: (state) => state.policy_perimeter_collapse,
    explicitlyLogout: (state) => !!state.explicitly_logout,
    isCollapsed: (state) => state.sidebar,
    isLoading: (state) => !!state.loading,
    perimeter: (state) => state.perimeter,
    tag: (state) => state.user.tag,
    local_perimeter_id: (state) => state.user.local_perimeter_id,
    local_tag_id: (state) => state.user.local_tag_id,
    isLocalProvider: (state) => state.user.is_local_provider,
    ptag_policy: (state) => state.ptag_policy,
    ptag_dashboard: (state) => state.ptag_dashboard,
    dashboard_cockpit: (state) => state.user.dashboard_cockpit,
    perimeter_indicator: (state) => state.perimeter_indicator,
    authStatus: (state) => state.status,
    customer_id: (state) =>
      state.user.customer_id ??
      state.user.customers.find((customer) => customer.name === state.user.customer)?.id ??
      null,
    customer: (state) => state.user.customer || '',
    customer_location: (state) => state.user.customer_location || '',
    customer_type: (state) => state.user.customer_type || '',
    none_label: () => 'Instance',
    user_tag: (state) => state.user.tag || '',
    user_perimeter: (state) => state.user.perimeter || '',
    customers: (state) => state.user.customers || [],
    name: (state) => state.user.name || '',
    logo_main: (state) => state.user.logo_main || '',
    initials: (state) => state.user.initials || '',
    email: (state) => state.user.email || '',
    lang: (state) => state.user.lang || '',
    key: (state) => state.user.key || '',
    instance: (state) => state.user.instance || '',
    instance_type: (state) => state.user.instance_type || '',
    instance_role: (state) => state.user.instance_role || '',
    user_persona_tenacy: (state) => state.user.user_persona_tenacy || '',
    impersonate_contributor: (state) => state.user.impersonate_contributor || false,
    sandbox_enable: (state) => state.user.sandbox_enable || false,
    isp_enable: (state) => state.user.isp_enable || false,
    risk_analysis_enable: (state) => state.user.risk_analysis_enable || false,
    multi_tenants_enable: (state) => state.user.multi_tenants_enable || false,
    enable_intercom: (state) => state.user.enable_intercom || false,
    onboarding_enabled: (state) => state.user.onboarding_enabled || false,
    onboarding_laststep: (state) => state.user.onboarding_laststep || '0',
    customer_options: (state) => state.user.customer_options || [],
    customer_features_flags: (state) => state.user.customer_features_flags || [],
    hasFeatureFlagAccess: (state) => (name) => {
      const features_flags = state.user.customer_features_flags || []
      return hasFeatureFlagAccess(features_flags, name)
    },
    is_sandbox: (state) => state.user.is_sandbox || false,
    isSandbox: (state) => state.user.is_sandbox || false,
    other_lang: (state) => {
      if (state.user.lang === 'en') {
        return 'fr'
      } else {
        return 'en'
      }
    },
    showOperated: (state) => state.show_operated || false,
    showSeparate: (state) => state.show_separate || false,
    showAutoMetrics: (state) => state.show_auto_metrics || false,
    showActivity: (state) => state.show_activity || false,
    cgu_accept: (state) => state.user.cgu_accept || false,
    hidePublicApi: (state) => {
      return !state.user.api_public_enable
    },
    features: (state) => state.user.features || [],
    hasFeature: (state) => (feature) => {
      let match = false
      if (state.user.features && feature) {
        if (state.user.features.includes(feature)) {
          match = true
        }
      }
      return match
    },
    namedRoles: (state) => {
      const namedRoles = []
      const roles = state.user.roles || ''

      const userRoleContext = {
        isGlobalAdmin: state.user.global_admin,
        isGlobalAuditor: state.user.global_auditor,
      }

      roles.split(',').forEach((roleHash) => {
        namedRoles.push(getUserRoleNameByRoleHash(roleHash, userRoleContext))
      })

      return namedRoles
    },
    hasNamedRole: (_state, getters) => (named_roles) => {
      let match = false
      if (getters.namedRoles && getters.namedRoles.length > 0 && named_roles) {
        for (let i = 0; i < named_roles.length && match === false; i++) {
          getters.namedRoles.every((user_named_role) => {
            if (user_named_role === named_roles[i]) {
              match = true
              return false
            }
            return true
          })
        }
      }
      return match
    },
    isPilote: (_state, getters) => {
      return getters.hasNamedRole(['root', 'global_pilote', 'local_pilote'])
    },
    isGlobalPiloteOrAuditor: (_state, getters) => {
      return getters.hasNamedRole(['root', 'global_pilote', 'global_auditor'])
    },
    isGlobalPilote: (state) => state.user.global_admin || false,
    isLocalPilote: (_state, getters) => {
      return getters.isPilote && !getters.isGlobalPilote
    },
    isPiloteOrAuditor: (_state, getters) => {
      return getters.hasNamedRole(['root', 'global_pilote', 'local_pilote', 'global_auditor', 'local_auditor'])
    },
    isAuditor: (_state, getters) => {
      return getters.hasNamedRole(['global_auditor', 'local_auditor'])
    },
    isRw: (_state, getters) => {
      return getters.hasNamedRole(['root', 'global_pilote', 'local_pilote', 'contributor', 'staff+'])
    },

    // TODO: replace au isContributor by isOnlyContributor
    isContributor: (_state, getters) => {
      return getters.isOnlyContributor
    },
    isOnlyContributor: (_state, getters) => {
      return (
        getters.hasNamedRole(['contributor']) &&
        !getters.hasNamedRole(['root', 'global_pilote', 'local_pilote', 'local_rw'])
      )
    },
    isOnlySupport: (_state, getters) => {
      return getters.hasNamedRole(['support']) && !getters.hasNamedRole(['staff+', 'root'])
    },
    // Root
    isAdmin: (_state, getters) => {
      return getters.hasNamedRole(['root'])
    },
    isStaff: (_state, getters) => {
      return getters.hasNamedRole(['root', 'staff', 'staff+'])
    },
    isSupport: (_state, getters) => {
      return getters.hasNamedRole(['support'])
    },
    isStaffPlus: (_state, getters) => {
      return getters.hasNamedRole(['staff+'])
    },
    isInstanceUser: (_state, getters) => {
      return getters.hasNamedRole(['root', 'staff', 'staff+', 'support'])
    },
    isPublic: (_state, getters) => {
      return (getters.isAdmin || getters.isStaffPlus || getters.isSupport) && getters.customer === getters.none_label
    },
    groupsusers: (state) => state.groupsusers,
    isDevEnv: (state) => state.is_dev_env,
    security_base_by_perimeters: (state) => state.security_base_by_perimeters || {},
    security_base_tab_index: (state) => state.security_base_tab_index || 0,
    security_base_perimeter: (state) => state.security_base_preferences.perimeter || null,
    security_base_score_type: (state) => state.security_base_preferences.score_type || null,
    per_page: (state) => state.per_page,
    per_page_options: () => [20],
    dashboard_preference: (state) => state.dashboard_preference || {},
    show_delta: (state) => state.dashboard_preference.show_delta || true,
    show_dots: (state) => state.dashboard_preference.show_dots || false,
    radar_previous_values: (state) => state.dashboard_preference.radar_previous_values || false,
    palette: (state) =>
      state.dashboard_preference.palette || [
        '#0A4650',
        '#108181',
        '#1f77b4',
        '#ff7f0e',
        '#2ca02c',
        '#d62728',
        '#9467bd',
        '#8c564b',
        '#e377c2',
        '#7f7f7f',
        '#bcbd22',
        '#17becf',
      ],
    show_sub_info: (state) => state.dashboard_preference.show_sub_info || true,
    show_icon: (state) => state.dashboard_preference.show_icon || true,
    show_pie_value: (state) => state.dashboard_preference.show_pie_value || false,
    is_pie: (state) => state.dashboard_preference.is_pie || false,
    custom_colors: (state) => state.dashboard_preference.custom_colors || false,
    show_legend: (state) => state.dashboard_preference.show_legend || true,
    dot_end_period: (state) => state.dashboard_preference.dot_end_period || false,
    dot_placement: (state) => state.dashboard_preference.dot_placement || false,
    offset_grid: (state) => state.dashboard_preference.offset_grid || false,
    comment_size: (state) => state.dashboard_preference.comment_size || 'md',
    pill_color: (state) => state.dashboard_preference.pill_color || '#555555',
    force_pill_color: (state) => state.dashboard_preference.force_pill_color || false,
    chart_y_auto: (state) => state.dashboard_preference.chart_y_auto || false,
    show_dots_lc: (state) => state.linechart_preference.show_dots || false,
    dot_placement_lc: (state) => state.linechart_preference.dot_placement || false,
    perimeter_tree_grouping: (state) => state.perimeter_tree_grouping || {},
    policyPriorityVisible: (state) => state.policy_priority_visible,
    policyMappingVisible: (state) => state.policy_mapping_visible,
    perimeters_outside_open: (state) => state.perimeters_outside_open,
    policy_score: (state) => state.policy_score,
    has_policy_benchmark: (state) => state.user.has_policy_benchmark,
    user_id: (state) => state.user.id,
    perimeter_score: (state) => state.perimeter_score,
    perimeter_risk: (state) => state.perimeter_risk,
    perimeter_run: (state) => state.perimeter_run,
    runbooks_open_groups: (state) => state.runbooks_open_groups,
    task_gantt_open_group: (state) => state.task_gantt_open_group,
    exemptions_open_groups: (state) => state.exemptions_open_groups,
    projects_open_groups: (state) => state.projects_open_groups,
    nb_reviews: (state) => state.nb_reviews,
    risk_analysis_step: (state) => state.tutorial_steps.risk_analysis_step,
    getTrialOption: (state) => (option_type) => {
      const option = state.user.customer_options.filter((option) => option.type === option_type)[0]
      return option ? option : null
    },
    return_recap_from_header: (state) => state.return_recap_from_header,
    risk_matrix_level: (state) => state.risk_matrix_level,
    matrix_display_risk: (state) => state.matrix_display_risk,
    risk_tile: (state) => state.risk_tile,
    risk_matrix_scale_level_perimeter: (state) => state.risk_matrix_scale_level_perimeter,
    is_zoomed: (state) => !!state.is_zoomed,
  },
  mutations: {
    set_zoom(state, isZoomed) {
      state.is_zoomed = isZoomed
    },
    set_main_title(state, data) {
      state.main_titles[data.route] = data.title
    },
    init_user(state) {
      state.user = {}
      state.perimeter = null
      state.tag = null
      state.ptag_policy = null
      state.ptag_dashboard = null
      state.perimeter_indicator = null
      state.explicitly_logout = true
      state.main_titles = {}
    },
    set_user(state, data) {
      // push to Matomo data layer (https://developer.matomo.org/guides/tagmanager/datalayer)
      window._mtm = window._mtm || []
      window._mtm.push({ role: data.user.instance_role })
      window._mtm.push({ persona: data.user.user_persona_tenacy })

      window._paq = window._paq || []
      window._paq.push(['setCustomDimension', '1', data.user.user_persona_tenacy])
      window._paq.push(['setCustomDimension', '2', data.user.instance_role])
      if (Vue.prototype.$apm) {
        const customer_name = data.user && data.user.customer ? data.user.customer : 'Instance'
        Vue.prototype.$apm.addLabels({ customer_name: customer_name })
      }
      state.token = data.token ?? null
      if (data.user) {
        for (const p in data.user) {
          Vue.set(state.user, p, data.user[p])
        }
      } else {
        state.user = {}
      }
    },
    set_risk_analysis_step(state, step) {
      state.tutorial_steps.risk_analysis_step = step
    },
    set_risk_analysis_enable(state, isEnabled) {
      state.user.risk_analysis_enable = isEnabled
    },
    set_multi_tenants_enable(state, isEnabled) {
      state.user.multi_tenants_enable = isEnabled
    },
    set_enable_intercom(state, isEnabled) {
      state.user.enable_intercom = isEnabled
    },
    set_onboarding_enabled(state, isEnabled) {
      state.user.onboarding_enabled = isEnabled
    },
    set_onboarding_laststep(state, step) {
      state.user.onboarding_laststep = step
    },
    set_customer_id(state, id) {
      state.user.customer_id = id
    },
    set_customer_name(state, name) {
      state.user.customer = name
    },
    set_isp_enable(state, isEnabled) {
      state.user.isp_enable = isEnabled
    },
    set_customer_options(state, option) {
      if (state.user.customer_options.find((opt) => opt.id === option.id)) {
        state.user.customer_options = state.user.customer_options.map((item) => {
          if (item.id === option.id) {
            return option
          } else {
            return item
          }
        })
      } else {
        state.user.customer_options.push(option)
      }
    },
    set_customer(state, customer_id) {
      let name = '?'
      state.user.customers.forEach((e) => {
        if (e.id === customer_id) {
          name = e.name
        }
      })
      state.user.customer_id = customer_id
      state.user.customer = name
      state.perimeter = null
      state.tag = null
      state.ptag_policy = null
      state.ptag_dashboard = null
      state.perimeter_indicator = null
    },
    set_connector_allowed(state, connector_allowed) {
      state.user.connector_allowed = connector_allowed
    },
    add_customer(state, customer) {
      state.user.customers.push(customer)
    },
    add_role(state, role) {
      state.user.roles += ',' + role
    },
    del_customer(state, customer_id) {
      const index = state.user.customers.findIndex((e) => e.id === customer_id)
      if (index >= 0) {
        state.user.customers.splice(index, 1)
      }
    },
    edit_customer(state, customer) {
      const index = state.user.customers.findIndex((e) => e.id === customer.id)
      if (index >= 0) {
        state.user.customers[index] = customer
      }
      if (state.user.customer && state.user.customer.id == customer.id) {
        state.user.customer = customer
      }
    },
    set_name(state, name) {
      state.user.name = name
    },
    set_email(state, email) {
      state.user.email = email
    },
    set_perimeter(state, perimeter) {
      state.perimeter = perimeter
    },
    set_tag(state, tag) {
      state.tag = tag
    },
    set_ptag_policy(state, ptag) {
      state.ptag_policy = ptag
    },
    set_ptag_dashboard(state, ptag) {
      state.ptag_dashboard = ptag
    },
    set_perimeter_indicator(state, ptag) {
      state.perimeter_indicator = ptag
    },
    set_lang(state, lang) {
      Vue.set(state.user, 'lang', lang)
    },
    set_impersonate_contributor(state, value) {
      Vue.set(state.user, 'impersonate_contributor', value)
    },
    set_global_admin(state, value) {
      Vue.set(state.user, 'global_admin', value)
    },
    cgu_accept(state, cgu_accept) {
      state.user.cgu_accept = cgu_accept
    },
    send_password_reminder(state, send_password_reminder) {
      state.user.send_password_reminder = send_password_reminder
    },
    mfa_activated(state, mfa_activated) {
      state.user.mfa_activated = mfa_activated
    },
    set_catalog_public(state, catalog_public) {
      state.catalog_public = catalog_public
    },
    jira_integration(state, jira_integration) {
      state.user.jira_integration = jira_integration
    },
    mfa_enabled(state, mfa_enabled) {
      state.user.mfa_enabled = mfa_enabled
    },
    set_collapse(state, bool) {
      state.sidebar = bool
    },
    set_customers(state, data) {
      state.user.customers = data
    },
    set_dashboard_cockpit(state, data) {
      state.user.dashboard_cockpit = data
    },
    set_show_separate(state, so) {
      state.show_separate = so
    },
    set_show_auto_metrics(state, so) {
      state.show_auto_metrics = so
    },
    set_show_activity(state, so) {
      state.show_activity = so
    },
    loading(state) {
      state.loading_cpt += 1
      state.loading = true
    },
    unloading(state) {
      state.loading_cpt -= 1
      if (state.loading_cpt <= 0) {
        state.loading = false
        state.loading_cpt = 0
      }
    },
    reset_loading(state) {
      state.loading_cpt = 0
      state.loading = false
    },
    groupsusers(state, groupsusers) {
      state.groupsusers = groupsusers
    },
    set_security_base_by_perimeters(state, v) {
      state.security_base_by_perimeters[v.id] = v.state
    },
    set_security_base_tab_index(state, v) {
      state.security_base_tab_index = v
    },
    set_security_base_perimeter(state, v) {
      state.security_base_preferences.perimeter = v
    },
    set_security_base_score_type(state, v) {
      state.security_base_preferences.score_type = v
    },
    update_nb_reviews(state, data) {
      if (data.nb_reviews || data.nb_reviews === 0) {
        state.nb_reviews = data.nb_reviews
      }
    },
    per_page(state, per_page) {
      state.per_page = per_page
    },
    set_perimeter_tree_grouping(state, v) {
      state.perimeter_tree_grouping[v.id] = v.state
    },
    set_show_delta(state, v) {
      state.dashboard_preference.show_delta = v
    },
    set_show_dots(state, v) {
      state.dashboard_preference.show_dots = v
    },
    show_min_max(state, v) {
      state.dashboard_preference.show_min_max = v
    },
    show_standard_deviation(state, v) {
      state.dashboard_preference.show_standard_deviation = v
    },
    set_radar_previous_values(state, v) {
      state.dashboard_preference.radar_previous_values = v
    },
    set_palette(state, v) {
      state.dashboard_preference.palette = v
    },
    set_show_sub_info(state, v) {
      state.dashboard_preference.show_sub_info = v
    },
    set_is_pie(state, v) {
      state.dashboard_preference.is_pie = v
    },
    set_show_icon(state, v) {
      state.dashboard_preference.show_icon = v
    },
    set_custom_colors(state, v) {
      state.dashboard_preference.custom_colors = v
    },
    set_show_legend(state, v) {
      state.dashboard_preference.show_legend = v
    },
    set_dot_end_period(state, v) {
      state.dashboard_preference.dot_end_period = v
    },
    set_dot_placement(state, v) {
      state.dashboard_preference.dot_placement = v
    },
    set_offset_grid(state, v) {
      state.dashboard_preference.offset_grid = v
    },
    set_comment_size(state, v) {
      state.dashboard_preference.comment_size = v
    },
    set_pill_color(state, v) {
      state.dashboard_preference.pill_color = v
    },
    set_force_pill_color(state, v) {
      state.dashboard_preference.force_pill_color = v
    },
    set_chart_y_auto(state, v) {
      state.dashboard_preference.chart_y_auto = v
    },
    set_show_dots_lc(state, v) {
      state.linechart_preference.show_dots = v
    },
    set_dot_placement_lc(state, v) {
      state.linechart_preference.dot_placement = v
    },
    set_policy_priority_visible(state, v) {
      state.policy_priority_visible = v
    },
    set_policy_mapping_visible(state, v) {
      state.policy_mapping_visible = v
    },
    set_perimeters_outside_open(state, v) {
      state.perimeters_outside_open = v
    },
    set_policy_score(state, v) {
      state.policy_score = v
    },
    set_has_policy_benchmark(state, v) {
      state.user.has_policy_benchmark = v
      void apiMethod.put('user/benchmark/' + state.user.id, { has_policy_benchmark: v }).then(() => {})
    },
    set_user_id(state, v) {
      state.user.id = v
    },
    set_perimeter_run(state, v) {
      state.perimeter_run = v
    },
    set_policy_perimeter_collapse(state, v) {
      state.policy_perimeter_collapse = v
    },
    set_runbooks_open_groups(state, v) {
      state.runbooks_open_groups = v
    },
    SET_TASK_GANTT_OPEN_GROUP(state, v) {
      state.task_gantt_open_group = v
    },
    set_exemptions_open_groups(state, v) {
      state.exemptions_open_groups = v
    },
    set_projects_open_groups(state, v) {
      state.projects_open_groups = v
    },
    set_me_show_auto(state, v) {
      state.me_show_auto = v
    },
    set_me_show_only_have_to_complete(state, v) {
      state.me_show_only_have_to_complete = v
    },
    set_me_show_only_my_metric(state, v) {
      state.me_show_only_my_metric = v
    },
    set_return_recap_from_header(state, v) {
      state.return_recap_from_header = v
    },
    set_hide_policy_suggestions(state, v) {
      state.hide_policy_suggestions = v
    },
    set_risk_matrix_level(state, level) {
      state.risk_matrix_level = level
    },
    set_matrix_display_risk(state, display) {
      state.matrix_display_risk = display
    },
    set_risk_matrix_scale_level_perimeter(state, level) {
      state.risk_matrix_scale_level_perimeter = level
    },
    set_risk_tile(state, tile) {
      state.risk_tile = tile
    },
    set_current_env(state) {
      const devEnvRegex = new RegExp('^(.*)dev(.*)$|^(.*)integration(.*)$|^(.*)localhost(.*)$')
      const currentHost = window.location.hostname
      const isDevEnv = devEnvRegex.test(currentHost)
      state.is_dev_env = isDevEnv
    },
  },
  actions: {
    setMainTitle({ commit }, data) {
      commit('set_main_title', data)
    },
    loadGroupsUsers({ commit }) {
      Vue.prototype.$http.get('/user/groupsusers').then((resp) => {
        commit('groupsusers', resp.data.objects)
      })
    },
    updateTutoStepAnalysis({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/risk_analysis/tuto_step')
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('set_risk_analysis_step', resp.data.object.step)
              resolve()
            } else {
              reject()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateNbReviews({ commit }) {
      return new Promise((_resolve, reject) => {
        axios({
          url: config.base_url + '/review/number',
          method: 'GET',
          headers: { 'x-page': window.location.pathname },
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('update_nb_reviews', resp.data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}

export default localStorage
