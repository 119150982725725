import { UserRoles, HashByUserRoles, RolesByHash, type UserRoleContext } from './UserRoles.interfaces'

export const getUserRoleNameByRoleHash = (
  hash: HashByUserRoles,
  userContext?: UserRoleContext
): UserRoles | undefined => {
  if (hash === HashByUserRoles.PILOT) {
    return userContext?.isGlobalAdmin ? UserRoles.GLOBAL_PILOT : UserRoles.LOCAL_PILOT
  }

  if (hash === HashByUserRoles.AUDITOR) {
    return userContext?.isGlobalAuditor ? UserRoles.GLOBAL_AUDITOR : UserRoles.LOCAL_AUDITOR
  }

  return RolesByHash[hash]
}
