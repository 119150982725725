export interface UserRoleContext {
  isGlobalAdmin?: boolean
  isGlobalAuditor?: boolean
}

export enum UserRoles {
  ROOT = 'root',
  STAFF_PLUS = 'staff+',
  STAFF = 'staff',
  GLOBAL_PILOT = 'global_pilote',
  LOCAL_PILOT = 'local_pilote',
  GLOBAL_AUDITOR = 'global_auditor',
  LOCAL_AUDITOR = 'local_auditor',
  CONTRIBUTOR = 'contributor',
  SUPPORT = 'support',
}

export enum HashByUserRoles {
  ROOT = '2E5B5WdvXd4HMEBYhWvPsFQnnD8An3Vc',
  STAFF_PLUS = 'E767xRWXb2WXkqgpam5rSGp2pE9TtxJq',
  STAFF = 'LX8de7YmX3WfDr4Ebd5ccPSn5YxHnxrW',
  PILOT = 'e5ut6VPS63KjpQpSewSnRYfv5GJWM7qg',
  AUDITOR = 'yChMth9DfQ8mmudBVxMLU3VtSuX2MN7C',
  CONTRIBUTOR = '9BJ8sWWKbahFfQDH7ub7Z9uV44dEzKPx',
  SUPPORT = 'WEekLVWdtqtKWVyVBvCMJgwZzrAhDsGD',
}

export const RolesByHash: Record<HashByUserRoles, UserRoles> = {
  [HashByUserRoles.ROOT]: UserRoles.ROOT,
  [HashByUserRoles.STAFF_PLUS]: UserRoles.STAFF_PLUS,
  [HashByUserRoles.STAFF]: UserRoles.STAFF,
  [HashByUserRoles.PILOT]: UserRoles.GLOBAL_PILOT,
  [HashByUserRoles.AUDITOR]: UserRoles.GLOBAL_AUDITOR,
  [HashByUserRoles.CONTRIBUTOR]: UserRoles.CONTRIBUTOR,
  [HashByUserRoles.SUPPORT]: UserRoles.SUPPORT,
}
